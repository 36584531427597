<template>
  <div v-if="behaviourNode" class="h-100">
    <b-overlay
      id="overlayBehaviourNodeDetailsSidebar"
      :show="isLoading && sideMenuDataLoading"
      style="min-height: 50%"
      rounded="sm"
      spinner-variant="primary"
      spinner-type="grow"
    >
      <div class="mini-mode-btn">
        <b-button
          title="Navigate to behaviour node"
          variant="flat-secondary"
          class="p-25 mr-25"
          @click="reShowNode"
        >
          <feather-icon icon="CrosshairIcon" :size="mini ? '16': '20'" />
        </b-button>
        <b-button
          :title="mini ? 'Switch to the full sidebar' : 'Switch to the mini-menu'"
          variant="flat-secondary"
          class="p-25"
          @click="toggleMiniMode"
          data-testid="ToggleMiniModeBtn"
        >
          <feather-icon :icon="mini ? 'Maximize2Icon' : 'Minimize2Icon'" :size="mini ? '16': '20'" />
        </b-button>
      </div>

      <div v-if="behaviourNode && behaviourNode.details">
        <b-row v-if="!mini" class="mt-1">
          <b-col>
            <div class="font-medium-3 font-weight-bold">
              {{ behaviourNode.details.cpt_name }}
            </div>
            <span class="font-medium-1">{{ behaviourNode.details.name }}</span>
          </b-col>
        </b-row>
        <span v-if="mini" class="font-small-2 text-primary">
          {{ behaviourNode.details.name }}
        </span>

        <hr v-if="!mini" class="mt-1">

        <b-tabs>
          <b-tab title="Allocations" ref="allocationsTab">
            <template #title>
              <feather-icon icon="LinkIcon" />
              <span>Allocations</span>
            </template>
            <list-group-entity
              label="Subject / Performer / Actor"
              :entity-array="subjects"
              modal=""
              class="mb-1"
            />
            <list-group-entity
              label="Objects"
              :entity-array="listObjects"
              modal=""
            />
            <hr class="mt-1">
            <list-group-entity
              label="Functions"
              :entity-array="functions"
              modal="allocate-bn-functions-single-modal"
              class="mb-1"
            />
            <list-group-interface
              label="Interfaces"
              :entity-array="behaviourNode.interfaces"
              modal=""
            />

            <hr
              v-if="(behaviourNode.refinements && behaviourNode.refinements.length > 0 ) ||
                (behaviourNode.references && behaviourNode.references.length > 0) ||
                (behaviourNode.abstracts && behaviourNode.abstracts.length > 0 )"
              class="mt-1"
            >
            <b-row v-if="behaviourNode.refinements && behaviourNode.refinements.length > 0">
              <b-col>
                <span class="font-medium-1 font-weight-bold mr-50">Refinements</span>
                <dl
                  class="mx-50"
                >
                  <dd v-for="ref in behaviourNode.refinements" :key="ref.id">
                    <a
                      v-b-tooltip.hover.top.noninteractive="'Navigate to refinement'"
                      class="m-25 badge badge-primary"
                      @click="routeToNodeInNewBT(ref.bt.id, ref.id)"
                    >
                      {{ ref.name }} - {{ ref.bt.name }}
                    </a>
<!--                    <a class="text-danger">DEL</a>-->
                  </dd>
                </dl>
              </b-col>
            </b-row>
            <b-row v-if="behaviourNode.abstracts && behaviourNode.abstracts.length > 0">
              <b-col>
                <span class="font-medium-1 font-weight-bold mr-50">Refined By</span>
                <dl
                  class="mx-50"
                >
                  <dd v-for="ref in behaviourNode.abstracts" :key="ref.id">
                    <a
                      v-b-tooltip.hover.top.noninteractive="'Navigate to refinement'"
                      class="m-25 badge badge-primary"
                      @click="routeToNodeInNewBT(ref.bt.id, ref.id)"
                    >
                      {{ ref.name }} - {{ ref.bt.name }}
                    </a>
                  </dd>
                </dl>
              </b-col>
            </b-row>
            <b-row v-if="behaviourNode.references && behaviourNode.references.length > 0">
              <b-col>
                <span class="font-medium-1 font-weight-bold mr-50">References</span>
                <dl
                  class="mx-50"
                >
                  <dd v-for="ref in behaviourNode.references" :key="ref.id">
                    <a
                      v-b-tooltip.hover.top.noninteractive="'Show reference'"
                      class="m-25 badge badge-primary"
                      @click="showNode(ref, selected_bt.id)"
                    >
                      {{ ref.name }} - [{{ ref.rel_type }}]
                    </a>
                  </dd>
                </dl>
              </b-col>
            </b-row>
            <b-row v-if="behaviourNode.backReferences && behaviourNode.backReferences.length > 0">
              <b-col>
                <span class="font-medium-1 font-weight-bold mr-50">Referenced By</span>
                <dl class="mx-50">
                  <dd v-for="ref in behaviourNode.backReferences" :key="ref.id">
                    <a
                      v-b-tooltip.hover.top.noninteractive="'Show reference'"
                      class="m-25 badge badge-info"
                      @click="showNode(ref, selected_bt.id)"
                    >
                      {{ ref.name }} - [{{ ref.rel_type }}]
                    </a>
                  </dd>
                </dl>
              </b-col>
            </b-row>

            <hr class="my-2">

            <Allocation />
          </b-tab>

          <b-tab v-if="!mini" :disabled="mini" title="Associations">
            <template #title>
              <feather-icon icon="LinkIcon" />
              <span>Associations</span>
            </template>

            <!-- Requirements -->
            <b-card no-body class="mb-1 border">
              <b-card-header header-tag="header" class="p-0" role="tab">
                <b-button-group class="w-100">
                  <b-button
                    v-b-tooltip.hover.top.noninteractive="'Associate Requirements'"
                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                    class="btn-icon"
                    variant="flat-success"
                    @click="$bvModal.show('associator-generic-requirements-behaviour-sidebar')"
                  >
                    <feather-icon
                      icon="LinkIcon"
                    />
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    v-b-toggle.context-requirements
                    variant="flat-primary"
                    class="d-inline-flex w-100 justify-content-between"
                    block
                  >
                    <p class="my-0 w-100 d-block">
                      Requirements
                    </p>
                    <b-badge class="" :variant="behaviourNode.requirements.length > 0 ? 'info' : 'muted'">
                      {{ behaviourNode.requirements.length ? behaviourNode.requirements.length : '-' }}
                    </b-badge>
                  </b-button>
                </b-button-group>
              </b-card-header>
              <b-collapse id="context-requirements" accordion="selected-context" role="tabpanel">
                <b-card-body>
                  <b-card-text v-if="behaviourNode.requirements.length > 0">
                    <b-table
                      id="tblBehaviourNodeRequirements"
                      :items="behaviourNode.requirements"
                      :fields="tblRequirementsFields"
                      thead-class="tblHeaderHidden"
                      hover
                    >
                      <template v-slot:cell(display_id)="data">
                        <router-link
                          :to="{
                            name: 'requirement_detail',
                            params: { requirementId: data.item.id },
                          }"
                        >
                          {{ data.item.id }}
                          <font-awesome-icon
                            :icon="['fas', 'external-link-alt']"
                            class="ml-50 font-small-2"
                          />
                        </router-link>
                      </template>
                      <template v-slot:cell(object_text)="rowData">
                        <div
                          v-b-tooltip.hover.top.noninteractive="'Navigate to Requirement'"
                          class="w-100 h-100"
                          @click="routeToRequirement(rowData.item)"
                        >
                          <span class="font-weight-bolder text-primary">{{ rowData.item.display_id }}</span> -
                          <span class="break-words" v-sanitized-html="rowData.item.object_text" />
                        </div>
                      </template>
                    </b-table>
                  </b-card-text>
                  <b-card-text v-else>
                    There are no associated <span class="font-weight-bolder">Requirements</span>.
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
            <!-- ./Requirements -->

            <!-- Issues -->
            <b-card no-body class="mb-1 border">
              <b-card-header header-tag="header" class="p-0" role="tab">
                <b-button-group class="w-100">
                  <b-button
                    v-b-tooltip.hover.top.noninteractive="'Associate Issues'"
                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                    class="btn-icon"
                    variant="flat-success"
                    @click="$bvModal.show('associator-generic-issues-behaviour-sidebar')"
                  >
                    <feather-icon
                      icon="LinkIcon"
                    />
                  </b-button>
                  <b-button
                    v-b-tooltip.hover.top.noninteractive="'Create Issue'"
                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                    class="btn-icon"
                    variant="flat-success"
                    @click="issueModalRaise(behaviourNode.details.id, 'BehaviourNode', getBn)"
                  >
                    <feather-icon
                      icon="PlusIcon"
                    />
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    v-b-toggle.context-issues
                    variant="flat-primary"
                    class="d-inline-flex w-100 justify-content-between"
                    block
                  >
                    <p style="margin-right: 2.75rem" class="my-0 w-100">
                      Issues
                    </p>
                    <b-badge class="" :variant="behaviourNode.issues.length > 0 ? 'info' : 'muted'">
                      {{ behaviourNode.issues.length ? behaviourNode.issues.length : '-' }}
                    </b-badge>
                  </b-button>
                </b-button-group>
              </b-card-header>
              <b-collapse id="context-issues" accordion="selected-context" role="tabpanel">
                <b-card-body>
                  <b-card-text v-if="behaviourNode.issues.length > 0">
                    <b-table
                      hover
                      :items="behaviourNode.issues"
                      :fields="tblIssuesFields"
                      thead-class="tblHeaderHidden"
                      @row-clicked="item => issueModalView(item.id, getBn)"
                    >
                      <template v-slot:cell(name)="rowData">
                        <div v-b-tooltip.hover.top.noninteractive="'Navigate to Issue'">
                          <p class="font-small-3 mt-0 pt-0">
                            <span class="font-weight-bolder mr-50 text-primary">{{ rowData.item.display_id }}</span>|
                            <span class="mr-50">Classification:</span>
                            <span class="font-weight-bolder mr-50">{{ rowData.item.classification }}</span>|
                            <span class="mr-50">Severity:</span>
                            <span class="font-weight-bolder mr-50">{{ rowData.item.severity }}</span>|
                            <span class="mr-50">Status:</span>
                            <span class="font-weight-bolder">{{ rowData.item.status }}</span>
                          </p>
                          <p v-sanitized-html="rowData.item.name" />
                          <!--<span class="font-weight-bolder text-primary">{{ rowData.item.display_id }}</span>-->
                          <!--<span class="break-words" v-sanitized-html="rowData.item.name" />-->
                        </div>
                      </template>
                    </b-table>
                  </b-card-text>
                  <b-card-text v-else>
                    There are no associated <span class="font-weight-bolder">Issues</span>.
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
            <!-- ./Issues -->

            <!-- Tests -->
            <b-card no-body class="mb-1 border">
              <b-card-header header-tag="header" class="p-0" role="tab">
                <b-button-group class="w-100">
                  <b-button
                    v-b-tooltip.hover.top.noninteractive="'Associate Test Cases'"
                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                    class="btn-icon"
                    variant="flat-success"
                    @click="$bvModal.show('associator-generic-tests-behaviour-sidebar')"
                  >
                    <feather-icon
                      icon="LinkIcon"
                    />
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    v-b-toggle.context-tests
                    variant="flat-primary"
                    class="d-inline-flex w-100 justify-content-between"
                    block
                  >
                    <p class="my-0 w-100">
                      Tests
                    </p>
                    <b-badge class="" :variant="behaviourNode.tests.length > 0 ? 'info' : 'muted'">
                      {{ behaviourNode.tests.length ? behaviourNode.tests.length : '-' }}
                    </b-badge>
                  </b-button>
                </b-button-group>
              </b-card-header>
              <b-collapse id="context-tests" accordion="selected-context" role="tabpanel">
                <b-card-body>
                  <b-card-text v-if="behaviourNode.tests.length > 0">
                    <b-table
                      hover
                      :items="behaviourNode.tests"
                      :fields="tblTestsFields"
                      thead-class="tblHeaderHidden"
                      @row-clicked="routeToTest"
                    >
                      <template v-slot:cell(name)="data">
                        <span class="font-weight-bolder text-primary">{{ data.item.ref_id }}</span> -
                        <span class="break-words">{{ data.item.name }}</span>
                      </template>

                      <template v-slot:cell(latest_result)="data">
                        <span
                          v-if="(data.item.latest_result && data.item.latest_result.result.toLowerCase() === 'passed')"
                          class="badge badge-success"
                        >
                          PASSED <span v-if="(data.item.latest_result && data.item.latest_result.build_name)"> <small>on {{ data.item.latest_result.build_name }}</small></span>
                        </span>
                        <span
                          v-else-if="(data.item.latest_result && data.item.latest_result.result.toLowerCase() === 'failed')"
                          class="badge badge-danger"
                        >
                          FAILED <span v-if="(data.item.latest_result && data.item.latest_result.build_name)"> <small>on {{ data.item.latest_result.build_name }}</small></span>
                        </span>
                        <span
                          v-else-if="(data.item.latest_result && data.item.latest_result.result)"
                          class="badge badge-secondary"
                        >
                          {{ data.item.latest_result.result }}
                        </span>
                        <span
                          v-else
                          class="badge badge-secondary"
                        >
                          Untested
                        </span>
                      </template>
                    </b-table>
                  </b-card-text>
                  <b-card-text v-else>
                    There are no associated <span class="font-weight-bolder">Tests</span>.
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
            <!-- ./Tests -->

            <!-- Notes -->
            <b-card no-body class="mb-1 border">
              <b-card-header header-tag="header" class="p-0" role="tab">
                <b-button-group class="w-100">
                  <b-button
                    v-b-tooltip.hover.top.noninteractive="'Add a note'"
                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                    class="btn-icon"
                    variant="flat-success"
                    @click="addNote"
                  >
                    <feather-icon
                      icon="PlusIcon"
                    />
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    v-b-toggle.context-notes
                    variant="flat-primary"
                    class="d-inline-flex w-100 justify-content-between"
                    block
                  >
                    <p class="my-0 w-100">
                      Notes
                    </p>
                    <b-badge class="" :variant="behaviourNode.notes.length > 0 ? 'info' : 'muted'">
                      {{ behaviourNode.notes.length ? behaviourNode.notes.length : '-' }}
                    </b-badge>
                  </b-button>
                </b-button-group>
              </b-card-header>
              <b-collapse id="context-notes" accordion="selected-context" role="tabpanel">
                <b-card-body>
                  <b-card-text v-if="behaviourNode.notes.length > 0">
                    <b-table
                      hover
                      :items="behaviourNode.notes"
                      :fields="tblNotesFields"
                      thead-class="tblHeaderHidden"
                    >
                      <template v-slot:cell(text)="data">
                        <small class="text-muted">{{ getUserUsername(data.item.created_by) }} ({{
                          data.item.updated | formatDate
                        }})</small>
                        <p class="mb-50" v-sanitized-html="data.item.text" />
                        <b-button size="sm" variant="flat-secondary" @click="updateNote(data.item)">
                          Edit
                        </b-button>
                        <b-button size="sm" variant="flat-danger" @click="deleteNote(data.item)">
                          Delete
                        </b-button>
                      </template>
                    </b-table>
                  </b-card-text>
                  <b-card-text v-else>
                    There are no associated <span class="font-weight-bolder">Notes</span>.
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
            <!-- ./Notes -->
            <div v-if="!mini">
              <div>
                <h5>Possible Integration/Reference Points</h5>
                <div v-if="potentialReferences.length > 0">
                  <b-table
                    hover
                    :items="potentialReferences"
                    :fields="tblNavFields"
                    thead-class="tblHeaderHidden"
                  >
                    <template v-slot:cell(name)="data">
                      <p class="mb-50">
                        {{ data.item.name }}
                      </p>
                      <b-button size="sm" variant="flat-secondary" @click="showNode(data.item, selected_bt.id)">
                        Show
                      </b-button>
                      <b-button size="sm" variant="flat-primary" @click="createReference(data.item)">
                        Reference
                      </b-button>
                      <b-button size="sm" variant="flat-primary" @click="createSynchronisation(data.item)">
                        Synchronise
                      </b-button>
  <!--                    <b-button size="sm" variant="flat-primary" @click="play(data.item)">-->
  <!--                      Integrate (This currently does nothing )-->
  <!--                    </b-button>-->
                    </template>
                  </b-table>
                </div>
                <div v-else>
                  <p class="warning mb-50">
                    No Potential Integration/Reference Points
                  </p>
                  <b-button size="sm" variant="outline-danger" @click="raiseXRefIssue('reference')">
                    Raise Issue
                  </b-button>
                </div>
              </div>
              <hr class="mt-1">
              <div>
                <h5>Possible Reversion Points</h5>
                <div v-if="potentialReversions.length > 0">
                  <b-table
                    hover
                    :items="potentialReversions"
                    :fields="tblNavFields"
                    thead-class="tblHeaderHidden"
                  >
                    <template v-slot:cell(name)="data">
                      <p class="mb-50">
                        {{ data.item.name }}
                      </p>
                      <b-button size="sm" class="mr-1" variant="outline-secondary" @click="showNode(data.item, selected_bt.id)">
                        Show
                      </b-button>
                      <b-button size="sm" variant="outline-primary" @click="createReversion(data.item)">
                        Revert
                      </b-button>
                    </template>
                  </b-table>
                </div>
                <div v-else>
                  <p class="warning mb-50">
                    No Potential Reversion Points
                  </p>
                  <b-button size="sm" variant="outline-danger" @click="raiseXRefIssue('reversion')">
                    Raise Issue
                  </b-button>
                </div>
              </div>
              <hr class="mt-1">
            </div>
            <b-button size="sm" variant="outline-success" @click="reShowNode()">
              Navigate Back to this Node
            </b-button>
          </b-tab>

          <b-tab v-if="!mini" ref="editTab">
            <template #title>
              <feather-icon icon="EditIcon" />
              <span>Edit Node</span>
            </template>

            <div class="d-inline-flex w-100 justify-content-between">
              <div class="w-100">
                <!-- Behaviour Node Type -->
                <b-form-group label="Type" label-for="bn-type-select">
                  <b-form-select id="bn-type-select" v-model="type" :options="type_options" class="w-100" />
                </b-form-group>
              </div>
              <div class="w-100 ml-2">
                <!-- Operator -->
                <b-form-group label="Operator" label-for="bn-operator-select">
                  <b-form-select id="bn-operator-select" v-model="operator" :options="operator_options" class="w-100" />
                </b-form-group>
              </div>
            </div>

            <div v-if="type !== 'FunctionNode'">
              <b-form-group>
                <label for="subject-input">
                  <span v-if="type === 'Quantification'">For Each</span>
                  <div v-else-if="type === 'Quantity'">
                    <!-- Using props -->
                    <b-input-group prepend="For up to " append=" of">
                      <b-form-input v-model="quantity" type="number" />
                    </b-input-group>
                  </div>
                  <span v-else>Subject / Performer</span>
                </label>
                <b-form-input
                  id="subject-input"
                  v-model="selected_performer"
                  list="subject-input-list"
                  placeholder="Performer"
                />
                <b-form-datalist id="subject-input-list">
                  <option v-for="performer in components" :key="performer.id">
                    {{ performer.name }}
                  </option>
                </b-form-datalist>
              </b-form-group>

              <div class="d-inline-flex w-100 justify-content-around">
                <div class="w-100">
                  <b-form-group label="Instance Name" label-for="instance-input">
                    <b-form-input id="instance-input" v-model="instance" placeholder="Instance name" class="w-100" />
                  </b-form-group>
                </div>
                <div v-if="type !== 'Quantification' && type !== 'Quantity'" class="ml-2 w-100">
                  <b-form-group label="Attribute" label-for="attribute-input">
                    <b-form-input id="attribute-input" v-model="attribute" placeholder="Attribute / Property" class="w-100" />
                  </b-form-group>
                </div>
              </div>
            </div>

            <div v-if="type !== 'Quantification' && type !== 'Quantity'" id="gridState" class="d-inline-flex w-100">
              <div class="w-100">
                <b-form-group>
                  <label for="action-input">
                    <span v-if="type === 'Event' || type === 'GuardedEvent'">Action</span>
                    <span v-else-if="type === 'State'">State</span>
                    <span v-else-if="type === 'Selection' || type === 'Condition'">Condition</span>
                    <span v-else-if="type === 'Assertion'">Assertion / Predicate</span>
                    <span v-else>Data / Resource</span>
                  </label>
                  <b-form-input id="action-input" v-model="action" placeholder="Action to perform" class="w-100" />
                </b-form-group>
              </div>
              <div class="w-25" style="padding-top: 2.25rem;">
                <b-form-checkbox
                  v-model="negated"
                  :value="true"
                  :unchecked-value="false"
                >
                  Negate
                </b-form-checkbox>
              </div>
            </div>

            <hr>

            <div v-if="type !== 'FunctionNode'" class="w-100">
              <h4 class="mb-1">
                Objects
              </h4>

                <div
                  v-for="(object, index) in objects"
                  :id="object.id"
                  :key="object.id"
                  class="w-100 mb-1"
                >
                  <div class="d-inline-flex">
                    <div>
                      <div class="d-inline-flex w-100 justify-content-between">
                        <b-form-group label="Preposition" :label-for="`txtPreposition-${index}`" class="w-100">
                          <b-form-input
                            :id="`txtPreposition-${index}`"
                            v-model="object.pre"
                            placeholder="Preposition"
                          />
                        </b-form-group>

                        <b-form-group label="Object Name" :label-for="`txtObjectName-${index}`" class="w-100 ml-1">
                          <b-form-input
                            :id="`txtObjectName-${index}`"
                            v-model="object.text"
                            list="object-list"
                            placeholder="Object name"
                          />
                          <b-form-datalist id="object-list">
                            <option v-for="performer in components" :key="performer.id">
                              {{ performer.name }}
                            </option>
                          </b-form-datalist>
                        </b-form-group>
                      </div>

                      <div class="d-inline-flex w-100 justify-content-between">
                        <b-form-group label="Instance Name" :label-for="`txtInstanceName-${index}`" class="w-100">
                          <b-form-input
                            :id="`txtInstanceName-${index}`"
                            v-model="object.instance"
                            placeholder="Instance name"
                          />
                        </b-form-group>

                        <b-form-group label="Object Attribute" :label-for="`txtObjectAttribute-${index}`" class="w-100 ml-1">
                          <b-form-input
                            :id="`txtObjectAttribute-${index}`"
                            v-model="object.attribute"
                            placeholder="Object Attribute"
                          />
                        </b-form-group>

                        <b-button
                          v-if="mini"
                          v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                          variant="flat-danger"
                          size="sm"
                          class="mt-1"
                          @click="removeObject(index)"
                        >
                          <feather-icon icon="TrashIcon" />
                        </b-button>
                      </div>
                    </div>

                    <b-button
                      v-if="!mini"
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="flat-danger"
                      size="sm"
                      @click="removeObject(index)"
                    >
                      <feather-icon icon="TrashIcon" />
                    </b-button>
                  </div>
                </div>

              <div class="w-100 d-inline-flex justify-content-center">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  data-cy="btn-behaviour-add-object"
                  variant="flat-primary"
                  size="sm"
                  @click="repeatObject"
                >
                  <feather-icon
                    icon="PlusIcon"
                    class="mr-25"
                  />
                  <span>Add Object</span>
                </b-button>
              </div>

              <hr v-if="type !== 'FunctionNode' && type !== 'Quantification' && type !== 'Quantity'">

              <div class="d-inline-flex w-100">
                <b-form-group label="Security Classification" label-for="edit_b_clas" class="w-100">
                  <b-form-select
                    id="edit_b_clas"
                    v-model="classification"
                    :options="securityClassifications"
                    class="w-100"
                  />
                </b-form-group>
              </div>
            </div>

            <div class="d-inline-flex w-100 justify-content-between mt-1">
              <div>
                <b-form-checkbox
                  v-model="propagateEdits"
                  :value="true"
                  :unchecked-value="false"
                >
                  {{ mini ? 'Propagate in Ontology' : 'Propagate changes through Ontology' }}
                </b-form-checkbox>
              </div>

              <b-button
                variant="success"
                :size="mini ? 'sm' : ''"
                class="mb-2"
                @click="submitFunction"
              >
                {{ mini ? 'Save' : 'Save changes' }}
              </b-button>
            </div>
          </b-tab>

          <b-tab v-if="!mini" :disabled="mini" title="Properties">
            <template #title>
              <feather-icon icon="ListIcon" />
              <span>Properties</span>
            </template>

            <b-row>
              <b-col cols="3">
                <h6 class="text-muted">
                  Property
                </h6>
              </b-col>
              <b-col cols="3">
                <h6 class="text-muted">
                  Value Expected
                </h6>
              </b-col>
              <b-col cols="3">
                <h6 class="text-muted">
                  Value Actual
                </h6>
              </b-col>
              <b-col cols="2">
                <h6 class="text-muted">
                  Unit
                </h6>
              </b-col>
            </b-row>

            <b-row class="mb-50">
              <b-col cols="3">
                <label for="ave-users">
                  Average # Concurrent Users
                </label>
              </b-col>
              <b-col cols="3">
                <b-form-input id="ave-users" v-model="aveUsersExpected" size="sm" type="number" />
              </b-col>
              <b-col cols="3">
                <b-form-input id="ave-users" v-model="aveUsersActual" size="sm" type="number" />
              </b-col>
              <b-col cols="2" />
            </b-row>
            <b-row class="mb-50">
              <b-col cols="3">
                <label for="peak-users">
                  Peak # Concurrent Users
                </label>
              </b-col>
              <b-col cols="3">
                <b-form-input id="peak-users" v-model="peakUsersExpected" size="sm" type="number" />
              </b-col>
              <b-col cols="3">
                <b-form-input id="peak-users" v-model="peakUsersActual" size="sm" type="number" />
              </b-col>
              <b-col cols="2" />
            </b-row>
            <b-row class="mb-50">
              <b-col cols="3">
                <label for="ave-tps">
                  Average Transaction Rate
                </label>
              </b-col>
              <b-col cols="3">
                <b-form-input id="ave-tps" v-model="aveTPSExpected" size="sm" type="number" />
              </b-col>
              <b-col cols="3">
                <b-form-input id="ave-tps" v-model="aveTPSActual" size="sm" type="number" />
              </b-col>
              <b-col cols="2">
                <b-form-input id="ave-tps-unit" v-model="aveTPSUnit" size="sm" placeholder="TPS" />
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="3">
                <label for="peak-tps">
                  Peak Transaction Rate
                </label>
              </b-col>
              <b-col cols="3">
                <b-form-input id="peak-tps" v-model="peakTPSExpected" size="sm" type="number" />
              </b-col>
              <b-col cols="3">
                <b-form-input id="peak-tps" v-model="peakTPSActual" size="sm" type="number" />
              </b-col>
              <b-col cols="2">
                <b-form-input id="peak-tps-unit" v-model="peakTPSUnit" size="sm" placeholder="TPS" />
              </b-col>
            </b-row>

            <hr>

            <div class="w-100 d-inline-flex justify-content-between">
              <label for="peak-tps" class="align-self-center">
                Probability
              </label>
              <b-form-input
                id="prob"
                v-model="probability"
                type="range"
                min="0"
                max="1.0"
                step="0.01"
                class="mx-2 align-self-center"
              />
              <b-form-input
                id="probText"
                v-model="probability"
                size="sm"
                type="number"
                min="0"
                max="1.0"
                step="0.1"
                class="w-25 align-self-center"
              />
            </div>

            <hr>

            <div>
              <b-form-group>
                <div
                  v-for="(property, index) in properties"
                  :id="property.id"
                  :key="property.id"
                  class="mb-1"
                >
                  <b-row>
                    <b-col cols="4">
                      <b-form-input
                        v-model="property.name"
                        placeholder="Prop. Name"
                      />
                    </b-col>
                    <b-col cols="3">
                      <b-form-input
                        v-model="property.valueExpected"
                        placeholder="Prop. Expected Value"
                      />
                    </b-col>
                    <b-col cols="3">
                      <b-form-input
                        v-model="property.valueActual"
                        placeholder="Prop. Actual Value"
                      />
                    </b-col>
                    <b-col cols="1">
                      <b-form-input
                        v-model="property.unit"
                        placeholder="Unit"
                      />
                    </b-col>
                    <b-col
                      cols="1"
                      class="pl-0"
                    >
                      <b-button
                        v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                        variant="outline-danger"
                        class="h-100"
                        size="sm"
                        @click="removeProperty(index)"
                      >
                        <feather-icon
                          icon="XIcon"
                        />
                      </b-button>
                    </b-col>

                  </b-row>
                </div>

                <b-row align-h="center">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="outline-primary"
                    size="sm"
                    @click="repeatProperty"
                  >
                    <feather-icon
                      icon="PlusIcon"
                      class="mr-25"
                    />
                    <span>Add Property</span>
                  </b-button>
                </b-row>
              </b-form-group>
            </div>

            <div class="w-100 d-inline-flex justify-content-end">
              <b-button variant="success" @click="updateProperties">
                Save changes
              </b-button>
            </div>
          </b-tab>

          <b-tab v-if="!mini" :disabled="mini" title="Constraints">
            <template #title>
              <feather-icon icon="CheckSquareIcon" />
              <span>Constraints</span>
            </template>
            <h4>Timing Constraints</h4>
            <b-row
              v-for="(timing, ind) in timingConstraints"
              :id="ind"
              :key="ind"
              class="mb-1"
            >
              <b-col>
                <b-row>
                  <b-col>
                    <hr class="my-2">
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="10">
                    <h4>From
                      <b-button variant="flat-primary" @click="showNode({id: timing.source}, selected_bt.id)">
                        {{ timing.source_nm }}
                      </b-button>
                      to
                      <b-button variant="flat-primary" @click="showNode({id: timing.target}, selected_bt.id)">
                        {{ timing.target_nm }}
                      </b-button>
                    </h4>
                  </b-col>
                  <b-col
                    cols="1"
                    class="pl-0"
                  >
                    <b-button
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="outline-danger"
                      class="h-100"
                      size="sm"
                      @click="removeTiming(timing.source, timing.target)"
                    >
                      <feather-icon
                        icon="XIcon"
                      />
                    </b-button>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col><b class="text-primary">Essential</b></b-col>
                </b-row>
                <b-row>
                  <b-col cols="2">
                    <b-form-select
                      v-model="timing.rel_props.E_comparator"
                      :options="comparatorOptions"
                      style="display: inline-block"
                    />
                  </b-col>
                  <b-col cols="5">
                    <b-form-input
                      v-model="timing.rel_props.E_constraint"
                      placeholder="Performance requirement - e.g. 3 seconds"
                      type="text"
                    />
                  </b-col>
                  <b-col cols="5">
                    <b-form-input
                      v-model="timing.rel_props.E_condition"
                      placeholder="Enter conditions on timing..."
                      type="text"
                    />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col><b class="text-primary">Very Important</b></b-col>
                </b-row>
                <b-row>
                  <b-col cols="2">
                    <b-form-select
                      v-model="timing.rel_props.VI_comparator"
                      :options="comparatorOptions"
                      style="display: inline-block"
                    />
                  </b-col>
                  <b-col cols="5">
                    <b-form-input
                      v-model="timing.rel_props.VI_constraint"
                      placeholder="Performance requirement - e.g. 3 seconds"
                      type="text"
                    />
                  </b-col>
                  <b-col cols="5">
                    <b-form-input
                      v-model="timing.rel_props.VI_condition"
                      placeholder="Enter conditions on timing..."
                      type="text"
                    />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b class="text-primary">Important</b>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="2">
                    <b-form-select
                      v-model="timing.rel_props.I_comparator"
                      :options="comparatorOptions"
                      style="display: inline-block"
                    />
                  </b-col>
                  <b-col cols="5">
                    <b-form-input
                      v-model="timing.rel_props.I_constraint"
                      placeholder="Performance requirement - e.g. 3 seconds"
                      type="text"
                    />
                  </b-col>
                  <b-col cols="5">
                    <b-form-input
                      v-model="timing.rel_props.I_condition"
                      placeholder="Enter conditions on timing..."
                      type="text"
                    />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b class="text-primary">Desirable</b>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="2">
                    <b-form-select
                      v-model="timing.rel_props.D_comparator"
                      :options="comparatorOptions"
                      style="display: inline-block"
                    />
                  </b-col>
                  <b-col cols="5">
                    <b-form-input
                      v-model="timing.rel_props.D_constraint"
                      placeholder="Performance requirement - e.g. 3 seconds"
                      type="text"
                    />
                  </b-col>
                  <b-col cols="5">
                    <b-form-input
                      v-model="timing.rel_props.D_condition"
                      placeholder="Enter conditions on timing..."
                      type="text"
                    />
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <hr class="my-2">
              </b-col>
            </b-row>

            <div class="w-100 d-inline-flex justify-content-end">
              <b-button variant="success" class="float-right" @click="updateTimings">
                Save changes
              </b-button>
            </div>
          </b-tab>

          <b-tab v-if="!mini && auth.roles && auth.roles.includes('administrator')" title="Permissions">
            <template #title>
              <feather-icon icon="EyeIcon" />
              <span>Permissions</span>
            </template>
            <permissions
              title="Permissions"
              node-type="BehaviourNode"
              :node-name="behaviourNode.details.name"
              :node-id="behaviourNode.details.id"
              :key="behaviourNode.details.id"
            />
          </b-tab>

          <b-tab v-if="!mini && auth.roles && auth.roles.includes('administrator')" title="Debug">
            <template #title>
              <feather-icon icon="CodeIcon" class="text-danger" />
              <span class="text-danger">Debug</span>
            </template>

            <h6 class="text-danger">
              Behaviour Node Details
            </h6>
            <pre>{{ behaviourNode.details }}</pre>

            <h6 class="text-danger">
              All Context Properties
              <span class="font-small-3">Including the above-mentioned details</span>
            </h6>
            <pre>{{ behaviourNode }}</pre>
          </b-tab>

          <!-- TODO: Reinstate this once performance issues resolved
          <b-tab v-if="!mini" :disabled="mini" title="Executions">
            <template #title>
              <feather-icon icon="ThermometerIcon" />
              <span>Record Execution</span>
            </template>
            <h5>Record Execution</h5>
            <div  v-if="behaviourNode.preconditions && behaviourNode.post">
              <b-card
                border-variant="primary"
              >
                <span class="font-medium-2 text-primary">Preconditions</span>
                <b-list-group flush>
                  <b-list-group-item
                    v-for="(item,index) in behaviourNode.preconditions"
                    :key="index"
                  >
                    <a
                      v-b-tooltip.hover.top.noninteractive="'Show precondition node'"
                      @click="highlightNode(item, selected_bt.id)"
                    >
                      {{ item.text }}
                    </a>
                  </b-list-group-item>
                </b-list-group>
              </b-card>
              <b-card
                title="Scenarios"
                border-variant="primary"
              >
                <b-list-group flush>
                  <b-list-group-item
                    v-for="(item,index) in behaviourNode.post"
                    :key="index"
                  >
                    <b-card border-variant="secondary">
                      <span class="font-medium-2 text-primary">Scenario {{ index + 1 }}</span>
                      <b-list-group flush>
                        <b-list-group-item
                          v-for="(qc,index2) in item.qualifiers"
                          :key="index2"
                        >
                          <a
                            v-b-tooltip.hover.top.noninteractive="'Show qualifier node'"
                            @click="highlightNode(qc, selected_bt.id)"
                          >
                            {{ qc.text }}
                          </a>
                        </b-list-group-item>
                      </b-list-group>
                      <span class="font-medium-1 text-primary">When these actions are performed</span>
                      <b-list-group flush>
                        <b-list-group-item
                          v-for="(ev,index3) in behaviourNode.triggers"
                          :key="index3"
                        >
                          <b-row>
                            <b-col cols="8">
                              <a
                                v-b-tooltip.hover.top.noninteractive="'Show event node'"
                                @click="highlightNode(ev, selected_bt.id)"
                              >
                                {{ ev.text }}
                              </a>
                            </b-col>
                            <b-col cols="2">
                              <b-form-checkbox
                                :id="`ev-checkbox-${index}-${index3}`"
                                :name="`ev-checkbox-${index}-${index3}`"
                                v-model="ev.executed"
                                class="mt-50"
                                style="display: inline-block"
                                :value="true"
                                :unchecked-value="false"
                              >
                                Confirmed
                              </b-form-checkbox>
                            </b-col>
                            <b-col>
                              <b-button
                                v-b-tooltip.hover.top.noninteractive="'Play Execution'"
                                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                                v-b-modal.create-and-allocate-issue-modal
                                class="btn-icon"
                                variant="flat-primary"
                              >
                                <feather-icon icon="PlayIcon" />
                              </b-button>
                            </b-col>
                          </b-row>
                        </b-list-group-item>
                      </b-list-group>
                      <span class="font-medium-1 text-primary">The expected outcome is</span>
                      <b-list-group flush>
                        <b-list-group-item
                          v-for="(eo,index4) in item.state"
                          :key="index4"
                        >
                          <b-row>
                            <b-col cols="8">
                              <a
                                v-b-tooltip.hover.top.noninteractive="'Show expected outcome node'"
                                @click="highlightNode(eo, selected_bt.id)"
                              >
                                {{ eo.text }}
                              </a>
                            </b-col>
                            <b-col cols="2">
                              <b-form-checkbox
                                :id="`eo-checkbox-${index}-${index4}`"
                                :name="`eo-checkbox-${index}-${index4}`"
                                v-model="eo.executed"
                                class="mt-50"
                                :value="true"
                                :unchecked-value="false"
                              >
                                Confirmed
                              </b-form-checkbox>
                            </b-col>

                          </b-row>
                        </b-list-group-item>
                      </b-list-group>
                      <b-row>
                        <b-col>
                          <b-button
                            v-b-tooltip.hover.top.noninteractive="'New Issue'"
                            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                            @click="openExecIssuesModal(index)"
                            class="btn-icon"
                            variant="flat-danger"
                          >
                            Raise Issue  <b-badge variant="dark"> {{ execIssues.length }} </b-badge>
                          </b-button>
                        </b-col>
                      </b-row>
                      <span class="font-medium-1 text-primary">Actual observed outcome</span>
                      <b-row>
                        <b-col cols="10">
                          <tip-tap-editor
                            v-model="item.actual"
                            placeholder="Record information about actual outcome observed..."
                            min-height="12"
                            max-height="12"
                          />
                        </b-col>
                        <b-col>
                          <b-button
                            v-b-tooltip.hover.top.noninteractive="'Save Execution'"
                            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                            class="btn-icon btn-lg"
                            variant="flat-success"
                            @click="saveSegmentCaseAndExecution(item, behaviourNode, true)"
                          >
                            Save Case & Execution
                          </b-button>
                          <b-button
                            v-b-tooltip.hover.top.noninteractive="'Save Execution'"
                            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                            class="btn-icon btn-lg"
                            variant="flat-secondary"
                            @click="saveSegmentCaseAndExecution(item, behaviourNode, false)"
                          >
                            Save Case Only
                          </b-button>
                        </b-col>
                      </b-row>
                      <span class="font-medium-1 text-primary">Continue to next step</span>
                      <b-row>
                        <b-col>
                          <div class="btn-group btn-group-justified">
                            <a
                              v-for="(ne,indexX) in item.next_events"
                              :key="indexX"
                               @click="showNode(ne, selected_bt.id)"
                              class="btn btn-secondary btn-sm"
                            >
                              {{ ne.text }}
                            </a>
                          </div>
                        </b-col>
                      </b-row>
                    </b-card>
                  </b-list-group-item>
                </b-list-group>
              </b-card>
            </div>
            <div v-else>
              <div class="text-warning">
                <small>
                  Insufficient information to construct test step, try selecting an Event/Action node.
                </small>
              </div>
              <b-list-group flush>
                <b-list-group-item
                  v-for="(item,index) in behaviourNode.post"
                  :key="index"
                >
                  <b-row>
                    <b-col>
                      <div class="btn-group btn-group-justified">
                        <a
                          v-for="(ne,indexX) in item.next_events"
                          :key="indexX"
                           @click="showNode(ne, selected_bt.id)"
                          class="btn btn-secondary btn-sm"
                        >
                          {{ ne.text }}
                        </a>
                      </div>
                    </b-col>
                  </b-row>
                </b-list-group-item>
              </b-list-group>
            </div>
            <hr class="mt-1">
            <div>
              <b-button size="sm" variant="flat-success" @click="reShowNode()">
                Navigate Back to this Node
              </b-button>
            </div>
            <small class="mt-1 float-right text-muted select-all">{{ behaviourNode.details.id }}</small>
          </b-tab>
          -->
        </b-tabs>
      </div>
    </b-overlay>

    <div id="behaviourSidebarModals">
      <AssociateFunctions
        v-if="behaviourNode && behaviourNode.details"
        :focused-entity-id="behaviourNode.details.id"
        :url-associated="`/api/v2/behaviour/nodes/${behaviourNode.details.id}/functions`"
      />
      <AssociateNote
        v-if="behaviourNode && behaviourNode.details"
        :focused-entity-id="behaviourNode.details.id"
        :url-associated="`/api/v2/behaviour/nodes/${behaviourNode.details.id}/notes`"
      />
      <UpdateNote
        v-if="behaviourNode && behaviourNode.details"
        :focused-entity-id="behaviourNode.details.id"
        :url-associated="`/api/v2/behaviour/nodes/${behaviourNode.details.id}/note/${selectedNote.id}`"
        :note="selectedNote.text"
        :note-id="selectedNote.id"
      />
      <DeleteNote
        v-if="behaviourNode && behaviourNode.details"
        :focused-entity-id="behaviourNode.details.id"
        :url-associated="`/api/v2/behaviour/nodes/${behaviourNode.details.id}/note/${selectedNote.id}`"
        :note="selectedNote.text"
      />
      <div v-for="assc in associators" :key="assc.singular">
        <GenericAssociator
          :id="`allocate-bn-${getPlural(assc)}-single-modal`"
          :fetch-filter-fn="fetchSharedModels"
          :fetch-fn="modelId => fetchComponents(modelId, `${getLeftOpts(assc)}`)"
          :update-fn="payload => linkComponents(payload, getPlural(assc))"
          :initial-prefilter="selectedModelComputed"
          :initial-list="getList(`${getPlural(assc)}`)"
          :filter-properties="['aliases']"
          :instant-save="false"
          prefilter-label="name"
          :type-name="assc.label"
        >
          <template #left="cpt">
            <b-badge v-for="l in cpt.labels.filter(lb => lb !== 'Component')" :key="l" class="mr-1" variant="primary">
              ({{ l }})
            </b-badge>
            <span v-if="cpt.aliases.length > 0" :title="cpt.name" class="mr-1 text-ellipsis">{{ cpt.name }} - AKA: {{ cpt.aliases.join(', ') }} </span>
            <span v-else :title="cpt.name" class="mr-1 text-ellipsis">{{ cpt.name }} </span>
          </template>
          <template #right="{ cpt, triggerSave }">
            <ComponentAllocatedListItem :component-badge="assc.singular" :component="cpt"
                                        :trigger-fn="triggerSave"
            />
          </template>
        </GenericAssociator>
      </div>

      <!-- Requirements -->
      <associator-generic
        name="Requirements"
        suffix="-behaviour-sidebar"
        :associated-items="selected_req"
        :all-items="allReq"
        @associated="associateRequirements"
      />
      <!-- Issues -->
      <associator-generic
        name="Issues"
        suffix="-behaviour-sidebar"
        :associated-items="selected_issue"
        :all-items="allIssues"
        @associated="associateIssues"
      />
      <!-- Objectives -->
      <associator-generic
        name="Objectives"
        suffix="-behaviour-sidebar"
        :associated-items="selectedObjectives"
        :all-items="allObjectives"
        @associated="associateObjectives"
      />
      <!-- Tests -->
      <associator-generic
        name="Tests"
        suffix="-behaviour-sidebar"
        :associated-items="selectedTests"
        :all-items="allTests"
        @associated="associateTestCases"
      />
      <!-- Enablers -->
      <Associator
        id="allocate-bn-enablers-modal"
        title="Allocate Enablers"
        left-label="Things"
        :left-opts="perfplus.map(x=>{return{value:x.id,text:x.name}})"
        right-label="Enablers"
        :right-opts="selectedEnablers"
        @ok="associateEnablers"
      />

      </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import {
  BCol,
  BOverlay,
  BRow,
  VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import AssociateFunctions from '@/components/AssociatorModals/AssociateFunctions.vue'
import AssociateNote from '@/components/AssociatorModals/AssociateNote.vue'
import UpdateNote from '@/components/Behaviours/Modals/UpdateNote.vue'
import DeleteNote from '@/components/Behaviours/Modals/DeleteNote.vue'
import AssociatorGeneric from '@/components/Generic/Associators/AssociatorGeneric.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import OrgChart from '@balkangraph/orgchart.js'
import Allocation from '@/components/Behaviours/Modals/Allocation.vue'
import ListGroupInterface from '@/components/Forms/ListGroups/ListGroupInterface.vue'
import ListGroupEntity from '@/components/Forms/ListGroups/ListGroupEntity.vue'
import Permissions from '@/components/Permissions/Permissions.vue'
import axiosIns from '@/libs/axios'
import Associator from '@/components/Forms/M_Associator.vue'
import TipTapEditor from '@/components/Forms/TipTapEditor/TipTapEditor.vue'
import details from '@/components/Pages/Details.vue'
import store from '@/store'
import GenericAssociator from '@/components/Forms/GenericAssociator.vue'
import ComponentAllocatedListItem from '@/components/Forms/ComponentAllocatedListItem.vue'
import coreService from '@/libs/api-services/core-service'

export default {
  components: {
    ComponentAllocatedListItem,
    GenericAssociator,
    // TipTapEditor,
    Associator,
    BRow,
    BCol,
    Allocation,
    AssociateFunctions,
    AssociateNote,
    UpdateNote,
    DeleteNote,
    AssociatorGeneric,
    ListGroupInterface,
    ListGroupEntity,
    Permissions,
    BOverlay,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    sideMenuDataLoading: {
      type: Boolean,
      required: true,
    },
    miniMode: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      isLoading: this.sideMenuDataLoading,
      mini: this.miniMode,
      tblRequirementsFields: [
        { key: 'object_text', label: 'Requirements' },
      ],
      tblIssuesFields: [
        { key: 'name', label: 'Issue' },
      ],
      tblNotesFields: [
        { key: 'text', label: 'Note' },
      ],
      tblObjectivesFields: [
        { key: 'name', label: 'Objective' },
      ],
      tblEnablerFields: [
        { key: 'name', label: 'Component' },
      ],
      tblTestsFields: [
        { key: 'name', label: 'Test Name' },
        { key: 'latest_result', label: 'Status' },
      ],
      tblNavFields: [
        { key: 'name', label: 'Node' },
      ],
      // Edit Modal Stuff
      // performers: [],
      resources: [],
      subjects: [],
      functions: [],
      // Top Row
      type: 'Event',
      type_options: [
        { value: 'Event', text: 'Event' },
        { value: 'State', text: 'State' },
        { value: 'Selection', text: 'Condition' },
        { value: 'GuardedEvent', text: 'Guarded Event' },
        { value: 'Input', text: 'Input' },
        { value: 'Output', text: 'Output' },
        { value: 'Assertion', text: 'Assertion' },
        { value: 'Quantification', text: 'Quantification' },
        { value: 'Quantity', text: 'Quantity' },
        { value: 'FunctionNode', text: 'Abstract Function' },
      ],
      comparatorOptions: [
        { value: '<=', text: '<=' },
        { value: '<', text: '<' },
        { value: '=', text: '=' },
        { value: '>=', text: '>=' },
        { value: '>', text: '>' },
        { value: '!=', text: '!=' },
      ],
      operator: 'no_operator',
      // Inputs
      selected_performer: '',
      action: '',
      behaviour_name: '',
      action_fn: '',
      negated: false,
      propagateEdits: true,
      classification: '',
      attribute: '',
      instance: '',
      // Form Repeater
      objects: [],
      listObjects: [],
      exchangedResources: [],
      nextObjectId: 0,
      nextPropId: 0,
      // Associators
      selected_req: [],
      allReq: [],
      selected_issue: [],
      allIssues: [],
      execIssues: [],
      selectedObjectives: [],
      allObjectives: [],
      selectedTests: [],
      allTests: [],
      selectedEnablers: [],
      quantity: 1,
      allEnablers: [],
      parseResults: {},
      properties: [],
      selectedNote: {
        text: '',
        id: '',
      },
      aveUsersExpected: '',
      aveUsersActual: '',
      peakUsersExpected: '',
      peakUsersActual: '',
      aveTPSExpected: '',
      aveTPSActual: '',
      aveTPSUnit: 'TPS',
      peakTPSExpected: '',
      peakTPSActual: '',
      peakTPSUnit: 'TPS',
      probability: 1.0,
      potentialReferences: [],
      potentialReversions: [],
      notOnNode: false,
      timingConstraints: [],
      issueProp: '',
      associators: [
        { singular: 'Function', label: 'Functions' },
      ],
    }
  },
  computed: {
    details() {
      return details
    },
    ...mapState({
      behaviourNode: state => state.behaviours.selectedBehaviourNode,
      selected_bt: state => state.behaviours.selectedBehaviourTree,
      auth: state => state.auth,
      performers: state => state.domainModel.performers,
      components: state => state.domainModel.components,
      selectedModelComputed: state => state.model,
    }),
    ...mapGetters({
      behaviourOperators: 'constants/behaviourOperators',
      securityClassifications: 'constants/securityClassifications',
    }),
    operator_options() {
      return this.behaviourOperators.map(x => ({
        value: x.id,
        html: `${x.display} - ${x.text}`,
      }))
    },
    perfplus() {
      const therest = this.components.filter(x => !(this.performers.map(p => p.id).includes(x.id)))
      const sep = [
        {
          id: null,
          name: '--------------',
        },
      ]
      return [...this.performers, ...sep, ...therest]
    },
  },
  watch: {
    behaviourNode(newbn, oldbn) {
      this.getData()
      if (this.mini) {
        const result = this.$refs.allocationsTab?.activate()
      }
    },
    mini(newMode) {
      if (newMode) {
        const result = this.$refs.allocationsTab?.activate()
      }
    },
    miniMode(newMode) {
      this.mini = newMode
    },
  },
  methods: {
    get_text(xml) {
      // This does the same thing as the BS4.get_text() on the server-side
      // Seems like most cases either render the textContent automatically or actually preserve the HTML
      // This is unused but has been tested
      const p = new DOMParser()
      const doc = p.parseFromString(xml, 'text/html')
      const els = Array.from(doc.querySelectorAll('body > *'))
      return els.reduce((r, e) => {
        r += e.textContent
        return r
      }, '')
    },
    getList(listName) {
      return this[listName]
    },
    getPlural(obj) {
      return obj?.plural || `${obj.singular.toLowerCase()}s`
    },
    getLeftOpts(obj) {
      return obj?.leftOpts === '' ? '' : obj?.leftOpts || this.getPlural(obj)
    },
    getBn() {
      this.$store.dispatch('behaviours/selectBehaviourNode', this.behaviourNode.details.id).then(() => {
        const params = { model: this.$store.state.model.id }
        axiosIns.get(`/api/v2/behaviour/nodes/${this.behaviourNode.details.id}/display`, { params })
          .then(({ data }) => {
            data.issues = this.behaviourNode.issues
            this.$emit('updated', data)
          })
      })
    },
    async fetchSharedModels() {
      return [this.$store.state.model, ...await coreService.modelApi.getLinkedModels(this.$store.state.model.id)]
    },
    async fetchComponents(modelId, subtype) {
      await this.$store.dispatch('domainModel/getComponentsForModel', { modelId, subtype: '' })
      if (modelId && this.$store.state.model.id !== modelId) {
        // now returns all components, not just the subtype
        return this.$store.state.domainModel[modelId].components
      }
      return this.$store.state.domainModel.components
    },
    async linkComponents(items, allocationType) {
      this.functions = items.map(x => ({
        id: x.id,
        name: x.name,
      }))
      const payload = {
        model: this.$store.state.model.id,
        fns: items.map(x => ({ id: x.id, justification: x.justification, start: x.start })),
        overwrite: true,
      }
      // old API does more than just link the BN to the function
      const url = `/api/v2/behaviour/nodes/${this.behaviourNode.details.id}/functions`
      try {
        await this.$http.post(url, payload)
        this.behaviourNode.fn_derived = this.functions
      } catch (e) {
        this.errorToast(`An error occurred when attempting to associate Functions with the BehaviourNode.
          Server returned Status ${e}`)
      }
    },
    errorToast(msg) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Failed to associate Functions',
          text: msg,
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    },
    routeToRequirement(requirementObj) {
      this.$bvModal
        .msgBoxConfirm('Are you sure you wish to leave this page? Any unsaved changes will be lost.', {
          title: 'Open Requirement',
          size: 'sm',
          okVariant: 'outline-danger',
          okTitle: 'Open Requirement',
          hideHeaderClose: false,
          centered: true,
        })
        .then(response => {
          if (response === true) {
            this.$router.push({
              name: 'requirements_table',
              params: { specId: requirementObj.spec_id },
              query: {
                selectedRequirement: requirementObj.id,
              },
            })
          }
        })
    },
    routeToNodeInNewBT(bt, bn) {
      this.$router.push({
        name: 'joint_mbse_tree_focus',
        params: { behaviourTreeId: bt },
        query: { focus: bn },
      })
    },
    routeToNodeInBT(bt, node) {
      this.routeToNodeInNewBT(bt, node)
    },
    routeToOntology(node) {
      this.$router.push(
        {
          name: 'domain_ontology_focus',
          params: { focus: node },
        },
      )
    },
    routeToFnOntology(node) {
      this.$router.push(
        {
          name: 'domain_ontology_focus',
          params: { focus: node },
        },
      )
    },
    routeToTest(test) {
      this.$router.push(
        {
          name: 'model_test_focus',
          params: { testId: test.id },
        },
      )
    },
    openExecIssuesModal(index) {
      this.issueProp = index
      this.$bvModal.show('create-and-allocate-issue-modal')
    },
    associateFunction() {
      this.$bvModal.show('allocate-bn-functions-single-modal')
    },
    showNode(bn, bt) {
      this.notOnNode = true
      if (bt === this.selected_bt.id) {
        this.$emit('update_focus', bn.id)
      } else {
        this.routeToNodeInNewBT(bt, bn.id)
      }
    },
    highlightNode(bn, bt) {
      this.notOnNode = true
      if (bt === this.selected_bt.id) {
        this.$emit('show_node', bn.id)
      } else {
        this.routeToNodeInNewBT(bt, bn.id)
      }
    },
    reShowNode() {
      this.notOnNode = false
      this.$emit('refocus', this.behaviourNode.details.id)
    },
    createReference(bn) {
      const params = {
        source: this.behaviourNode.details.id,
        target: bn.id,
        bt: this.selected_bt.id,
        relationship: 'reference',
        model: this.$store.state.model.id,
      }
      axiosIns.post('/api/v2/behaviour/create_bn_relationship', params).then(() => {
        this.$emit('post_reference', bn.id, 'reference')
      })
    },
    createSynchronisation(bn) {
      const params = {
        source: this.behaviourNode.details.id,
        target: bn.id,
        bt: this.selected_bt.id,
        relationship: 'synchronise',
        model: this.$store.state.model.id,
      }
      axiosIns.post('/api/v2/behaviour/create_bn_relationship', params).then(() => {
        this.$emit('post_reference', bn.id, 'synchronise')
      })
    },
    createReversion(bn) {
      const params = {
        source: this.behaviourNode.details.id,
        target: bn.id,
        bt: this.selected_bt.id,
        relationship: 'reversion',
        model: this.$store.state.model.id,
      }
      axiosIns.post('/api/v2/behaviour/create_bn_relationship', params).then(() => {
        this.$emit('post_reference', bn.id, 'reversion')
      })
    },
    associateRequirements(reqs) {
      // this.$bvModal.show('allocate-requirements-modal')
      const payload = {
        model: this.$store.state.model.id,
        requirements: reqs,
        overwrite: true,
      }
      this.$http
        .post(`/api/v2/behaviour/nodes/${this.behaviourNode.details.id}/requirements`, payload)
        .then(() => {
          this.$store.dispatch('behaviours/selectBehaviourNode', this.behaviourNode.details.id)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Requirements associated',
              text: 'Successfully associated Requirements with the Behaviour Node',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
        })
        .catch(r => {
          console.error(`[AssociateRequirementsModal] submit failed - ${r}`)
          console.error(r)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to associate Requirements',
              text: `An error occurred when attempting to associate Requirements with the Behaviour Node.
              Server returned Status ${r}`,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    associateIssues(issues, overwrite = true) {
      // this.$bvModal.show('allocate-issues-modal')
      const payload = {
        model: this.$store.state.model.id,
        issues,
        overwrite,
      }
      this.$http
        .post(`/api/v2/behaviour/nodes/${this.behaviourNode.details.id}/issues`, payload)
        .then(({ data }) => {
          console.log('Result from issue association: ', data)
          this.$store.dispatch('behaviours/selectBehaviourNode', this.behaviourNode.details.id).then(() => {
            console.log('Result from issue association: ', data, this.behaviourNode)
            // Add the issues list to the result passed back to the node
            const contextResult = this.$store.state.behaviours.selectedBehaviourNode
            data.result.issues = contextResult.issues
            this.$emit('updated', data.result)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Issues associated',
                text: 'Successfully associated Issues with the Behaviour Node',
                icon: 'AlertTriangleIcon',
                variant: 'success',
              },
            })
          })
        })
        .catch(r => {
          console.error(`[IssueAllocateModal] submitIssueAllocations failed - ${r}`)
          console.error(r)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to associate Issues',
              text: `An error occurred when attempting to associate Issues with the Behaviour Node.
              Server returned Status ${r}`,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    associateIssuesExec(issue) {
      // Update local data
      this.execIssues.push(
        {
          value: {
            id: issue.id,
            toSortBy: issue.display_id,
          },
          text: `${issue.display_id}. ${issue.name?.replace(/<\/?[^>]+(>|$)/g, '') ?? ''}`,
        },
      )
      this.issueProp = ''
      console.log('Execution Issues: ', this.execIssues)
    },
    raiseXRefIssue(refType) {
      const payload = {
        model: this.$store.state.model.id,
        name: `No ${refType} point can be found for the behaviour ${this.behaviourNode.details.name}`,
        description: '',
        classification: 'Incomplete',
        severity: 'Major',
        status: 'New',
      }
      this.$http
        .post('/api/v2/issues', payload)
        .then(({ data }) => {
          console.log('Created Issue: ', data)
          this.associateIssues([data.id], false)
        })
        .catch(r => console.error(r))
    },
    associateNewIssueBN(issue) {
      // Update local data
      this.allIssues.push(issue)
      this.selected_issue.push(
        {
          value: {
            id: issue.id,
            toSortBy: issue.display_id,
          },
          text: `${issue.display_id}. ${issue.name?.replace(/<\/?[^>]+(>|$)/g, '') ?? ''}`,
        },
      )
      const issues = this.selected_issue.map(x => (x.value.id))

      // update the DB
      this.associateIssues(issues)
    },
    associateObjectives(objectives) {
      // this.$bvModal.show('allocate-objectives-modal')
      const payload = {
        model: this.$store.state.model.id,
        objectives,
        overwrite: true,
      }
      this.$http
        .post(`/api/v2/behaviour/nodes/${this.behaviourNode.details.id}/objectives`, payload)
        .then(() => {
          this.$store.dispatch('behaviours/selectBehaviourNode', this.behaviourNode.details.id)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Objectives associated',
              text: 'Successfully associated Objectives with the BehaviourNode',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
        })
        .catch(r => {
          console.error(`[AssociateObjectivesModal] submit failed - ${r}`)
          console.error(r)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to associate Objectives',
              text: `An error occurred when attempting to associate Objectives with the BehaviourNode.
              Server returned Status ${r}`,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    associateEnablers(evt, enablers) {
      evt.preventDefault()
      // this.$bvModal.show('allocate-enablers-modal')
      const payload = {
        model: this.$store.state.model.id,
        enablers: enablers.map(x => (x.value)),
        overwrite: true,
      }
      this.$http
        .post(`/api/v2/behaviour/nodes/${this.behaviourNode.details.id}/enablers`, payload)
        .then(() => {
          this.$store.dispatch('behaviours/selectBehaviourNode', this.behaviourNode.details.id)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Enablers associated',
              text: 'Successfully associated Enablers with the BehaviourNode',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
        })
        .catch(r => {
          console.error(`[AssociateEnablersModal] submit failed - ${r}`)
          console.error(r)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to associate Enablers',
              text: `An error occurred when attempting to associate Enablers with the BehaviourNode.
              Server returned Status ${r}`,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    associateTestCases(tests) {
      // this.$bvModal.show('allocate-tests-modal')
      const payload = {
        model: this.$store.state.model.id,
        tests,
        overwrite: true,
      }
      this.$http
        .post(`/api/v2/behaviour/nodes/${this.behaviourNode.details.id}/tests`, payload)
        .then(() => {
          this.$store.dispatch('behaviours/selectBehaviourNode', this.behaviourNode.details.id)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Tests associated',
              text: 'Successfully associated Tests with the BehaviourNode',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
        })
        .catch(r => {
          console.error(`[AssociateTestCasesModal] submit failed - ${r}`)
          console.error(r)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to associate Tests',
              text: `An error occurred when attempting to associate Tests with the BehaviourNode.
              Server returned Status ${r}`,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    addNote() {
      this.$bvModal.show('allocate-notes-modal')
    },
    updateNote(note) {
      this.selectedNote = note
      this.$bvModal.show('update-notes-modal')
    },
    deleteNote(note) {
      this.selectedNote = note
      this.$bvModal.show('delete-note-modal')
    },
    play(d) {
      console.log('Do Something with: ', d)
    },
    // Edit Modal Stuff
    getData(isKeepData = false) {
      // const modelId = this.$store.state.model.id
      // this.$http.get('/api/v2/domain_model/get_composition_subtree', { params: { model: modelId } })
      //   .then(({ data }) => {
      //     this.performers = data.nodes.map(x => ({
      //       value: x.id,
      //       text: x.name,
      //     }))
      //   })

      // If statement helps prevent data loss if user switches between mini and full modes without saving
      if (!isKeepData) {
        this.type = this.behaviourNode.details.type
        this.operator = this.behaviourNode.details.operator
        this.selected_performer = this.behaviourNode.details.cpt_name
        this.subjects = [this.behaviourNode.subject]
        this.action = this.behaviourNode.details.behaviour_name
        this.behaviour_name = this.behaviourNode.details.behaviour_name
        this.negated = (this.behaviourNode.details.negated && (this.behaviourNode.details.negated === 'True' || this.behaviourNode.details.negated === 'true' || this.behaviourNode.details.negated === true))
        this.classification = this.behaviourNode.details.classification || this.$store.state.model.defaultSecurityClassification
        this.attribute = this.behaviourNode.details.attribute
        this.instance = this.behaviourNode.details.instance_name
        this.quantity = this.behaviourNode.details.quantity
      }

      this.aveUsersExpected = this.behaviourNode.details.aveUsersExpected || ''
      this.aveUsersActual = this.behaviourNode.details.aveUsersActual || ''
      this.peakUsersExpected = this.behaviourNode.details.peakUsersExpected || ''
      this.peakUsersActual = this.behaviourNode.details.peakUsersActual || ''
      this.aveTPSExpected = this.behaviourNode.details.aveTPSExpected || ''
      this.aveTPSActual = this.behaviourNode.details.aveTPSActual || ''
      this.aveTPSUnit = this.behaviourNode.details.aveTPSUnit || 'TPS'
      this.peakTPSExpected = this.behaviourNode.details.peakTPSExpected || ''
      this.peakTPSActual = this.behaviourNode.details.peakTPSActual || ''
      this.peakTPSUnit = this.behaviourNode.details.peakTPSUnit || 'TPS'
      this.probability = this.behaviourNode.details.probability || 1.0

      this.execIssues = []

      this.timingConstraints = this.behaviourNode.timing

      this.properties = this.behaviourNode.properties || []
      this.functions = this.behaviourNode.fn_derived || [] // This is the change

      const lObjects = this.behaviourNode.rel_parts
      const lIds = []
      this.objects = []
      this.listObjects = []
      if (this.behaviourNode.details.io_resource && this.behaviourNode.details.io_resource_name) {
        this.listObjects.push({
          id: this.behaviourNode.details.io_resource,
          name: this.behaviourNode.details.io_resource_name,
        })
        lIds.push(this.behaviourNode.details.io_resource)
      }
      if (this.behaviourNode) {
        const refs = store.state.behaviours.selectedBehaviourTree.references.filter(r => r.target === this.behaviourNode.details.id)
        this.behaviourNode.backReferences = refs.map(r => ({
          id: r.source,
          rel_type: r.rel_type,
          name: store.state.behaviours.selectedBehaviourTree.nodes.find(n => n.id === r.source)?.name || '',
        }))
      }
      for (let i = 0; i < lObjects.length; i++) {
        this.objects.push({
          id: lObjects[i].rel_obj_props.object,
          name: lObjects[i].rel_obj_props.object_name,
          pre: lObjects[i].rel_obj_props.preposition || '',
          text: lObjects[i].rel_obj_props.object_name,
          attribute: lObjects[i].rel_obj_props.attribute,
          instance: lObjects[i].rel_obj_props.instance_name,
        })
        // Don't display duplicates
        if (!lIds.includes(lObjects[i].rel_obj_props.object)) {
          this.listObjects.push({
            id: lObjects[i].rel_obj_props.object,
            name: lObjects[i].rel_obj_props.object_name,
          })
          lIds.push(lObjects[i].rel_obj_props.object)
        }
      }
      // get potential lists
      this.getIntegrations(this.behaviourNode.details.id, this.selected_bt.id)

      this.selected_req = this.behaviourNode.requirements.map(item => ({
        value: {
          id: item.id,
          toSortBy: item.display_id,
        },
        text: `${item.display_id}. ${item.object_text?.replace(/<\/?[^>]+(>|$)/g, '') ?? ''}`,
      }))
      this.fetchAllRequirements()

      this.selected_issue = this.behaviourNode.issues.map(item => ({
        value: {
          id: item.id,
          toSortBy: item.display_id,
        },
        text: `${item.display_id}. ${item.name?.replace(/<\/?[^>]+(>|$)/g, '') ?? ''}`,
      }))
      this.fetchAllIssues()

      this.selectedObjectives = this.behaviourNode.objectives.map(item => ({
        value: {
          id: item.id,
          toSortBy: item.name,
        },
        text: item.name,
      }))
      this.fetchAllObjectives()

      this.selectedTests = this.behaviourNode.tests.map(item => ({
        value: {
          id: item.id,
          toSortBy: item.ref_id,
        },
        text: `${item.ref_id} - ${item.name}`,
      }))
      this.fetchAllTests()

      this.selectedEnablers = this.behaviourNode.enablers.map(item => ({
        value: {
          id: item.id,
          toSortBy: item.name,
        },
        text: item.name,
      }))
      // this.fetchAllEnablers()
    },
    getIntegrations(bn, bt) {
      const params = { model: this.$store.state.model.id }
      this.$http
        .get(`/api/v2/behaviour/get_bn_integrations/${bn}/${bt}`, { params })
        .then(({ data }) => {
          this.potentialReferences = data.references || []
          this.potentialReversions = data.reversions || []
        })
    },
    fetchAllRequirements() {
      store.dispatch('requirements/getRequirementsSimple')
        .then(() => {
          if (!store.state.requirements.requirements) return
          this.allReq = store.state.requirements.requirements.map(item => ({
            value: {
              id: item.id,
              toSortBy: item.display_id,
            },
            text: `${item.display_id}. ${item.text}`,
          }))

          // Remove already associated elements from the allItems array
          this.selected_req.forEach(elementA => {
            const indexB = this.allReq.findIndex(elementB => elementA.value === elementB.value)
            if (indexB >= 0) {
              this.allReq.splice(indexB, 1)
            }
          })
          // Sort the allReq array by the toSortBy property in lexicographic order ascending
          this.behaviourNode.requirements.sort((a, b) => a.display_id.localeCompare(b.display_id))
        })
        .catch(r => {
          console.error(`[AssociateRequirementsModal] fetchAll failed - ${r}`)
        })
    },
    fetchAllIssues() {
      const params = { model: this.$store.state.model.id }
      this.$http
        .get('/api/v2/issues', { params })
        .then(({ data }) => {
          this.allIssues = data.map(item => ({
            value: {
              id: item.id,
              toSortby: item.display_id,
            },
            text: `${item.display_id}. ${item.name?.replace(/<\/?[^>]+(>|$)/g, '') ?? ''}`,
          }))

          // Remove already associated elements from the allIssues array
          this.selected_issue.forEach((elementA, indexA) => {
            this.allIssues.forEach((elementB, indexB) => {
              if (elementA.value === elementB.value) {
                this.allIssues.splice(indexB, 1)
              }
            })
          })
        })
        .catch(r => {
          console.error(`[IssueAllocateModal] fetchAllIssues failed - ${r}`)
        })
    },
    fetchAllObjectives() {
      const params = { model: this.$store.state.model.id }
      this.$http
        .get('/api/v2/canvas/objectives', { params })
        .then(({ data }) => {
          this.allObjectives = data.filter(x => x.name !== 'Objective').map(item => ({
            value: {
              id: item.id,
              toSortBy: item.name,
            },
            text: item.name,
          }))

          // Remove already associated elements from the allItems array
          this.selectedObjectives.forEach((elementA, indexA) => {
            this.allObjectives.forEach((elementB, indexB) => {
              if (elementA.value === elementB.value) {
                this.allObjectives.splice(indexB, 1)
              }
            })
          })
        })
        .catch(r => {
          this.loadingAll = true
          console.error(`[AssociateObjectivesModal] fetchAll failed - ${r}`)
        })
    },
    fetchAllTests() {
      const params = { model: this.$store.state.model.id }
      this.$http
        .get('/api/v2/tests', { params })
        .then(({ data }) => {
          this.allTests = data.map(item => ({
            value: {
              id: item.id,
              toSortBy: item.ref_id,
            },
            text: `${item.ref_id} - ${item.name}`,
          }))

          // Remove already associated elements from the allItems array
          this.selectedTests.forEach((elementA, indexA) => {
            this.allTests.forEach((elementB, indexB) => {
              if (elementA.value === elementB.value) {
                this.allTests.splice(indexB, 1)
              }
            })
          })
        })
        .catch(r => {
          console.error(`[AssociateTestCasesModal] fetchAll failed - ${r}`)
        })
    },
    fetchAllEnablers() {
      const params = { model: this.$store.state.model.id }
      this.$http
        .get('/api/v2/domain_model/get_composition_subtree', { params })
        .then(({ data }) => {
          this.allEnablers = data.nodes.map(item => ({
            value: {
              id: item.id,
              toSortBy: item.name,
            },
            text: item.name,
          }))

          // Remove already associated elements from the allItems array
          this.selectedEnablers.forEach((elementA, indexA) => {
            this.allEnablers.forEach((elementB, indexB) => {
              if (elementA.value === elementB.value) {
                this.allEnablers.splice(indexB, 1)
              }
            })
          })
        })
        .catch(r => {
          console.error(`[AssociateEnablersModal] fetchAll failed - ${r}`)
        })
    },
    submitFunction(evt) {
      // evt.preventDefault()
      // console.log('Do It!!!')
      const node = this.behaviourNode.details.id
      const params = {}
      params.model = this.$store.state.model.id
      if (this.type !== 'FunctionNode') {
        params.component = this.selected_performer
        params.type = this.type
        // Flag IO resource change
        if (this.type === 'Input' || this.type === 'Output') {
          if (this.action !== this.behaviour_name) {
            params.io_resource = this.action
          }
        }
        params.behaviour_name = this.action
        params.operator = this.operator
        params.negated = this.negated
        params.classification = this.classification
        params.attribute = this.attribute
        params.quantity = this.quantity
        params.instance_name = this.instance
        params.objects = this.objects.map(e => ({
          preposition: e.pre ? e.pre : '',
          object: e.text ? e.text : '',
          attribute: e.attribute ? e.attribute : '',
          instance_name: e.instance ? e.instance : '',
        }))
        params.parent = this.behaviourNode.details.id
        params.parent_rel = 'sequence'
        params.operator = this.operator
        params.bts = [this.selected_bt.id]
        params.function = ''
        params.direction = 'after'
        if (params.type === 'Event') {
          params.function = this.action
          params.objects.forEach(o => {
            if (o.preposition !== '') {
              params.function += ` ${o.preposition} ${o.object}`
            } else {
              params.function += ` ${o.object}`
            }
          })
        }
        params.precondition = []
        params.postcondition = []
        params.propagate = this.propagateEdits
      } else {
        params.component = this.action
        params.type = 'FunctionNode'
        params.behaviour_name = this.action
        params.operator = this.operator
        params.attribute = ''
        params.objects = []
        params.parent = this.behaviourNode.details.id
        params.parent_rel = 'sequence'
        params.operator = this.operator
        params.negated = this.negated
        params.bts = [this.selected_bt.id]
        params.function = this.action
        params.direction = 'after'
        params.precondition = []
        params.postcondition = []
        params.propagate = this.propagateEdits
      }
      this.isLoading = true
      this.$http.put(`/api/v2/behaviour/update_behaviour/${node}`, params)
        .then(({ data }) => {
          this.isLoading = false
          data.id = node
          this.$emit('updated', data)
        })
    },

    async updateProperties(evt) {
      // evt.preventDefault()
      // console.log('Do It!!!')
      const node = this.behaviourNode.details.id
      const params = {}
      params.model = this.$store.state.model.id
      params.aveTPSExpected = this.aveTPSExpected
      params.aveTPSActual = this.aveTPSActual
      params.aveTPSUnit = this.aveTPSUnit
      params.peakTPSExpected = this.peakTPSExpected
      params.peakTPSActual = this.peakTPSActual
      params.peakTPSUnit = this.peakTPSUnit
      params.aveUsersExpected = this.aveUsersExpected
      params.aveUsersActual = this.aveUsersActual
      params.peakUsersExpected = this.peakUsersExpected
      params.peakUsersActual = this.peakUsersActual
      if (this.probability >= 0 && this.probability <= 1) {
        params.probability = this.probability
      }
      params.properties = this.properties
      await this.$http.post(`/api/v2/behaviour/update_behaviour_properties/${node}`, params)
      this.isLoading = false
      params.id = node
      this.$emit('updated', params)
    },
    updateTimings(evt) {
      // evt.preventDefault()
      // console.log('Do It!!!')
      const node = this.behaviourNode.details.id
      const params = {}
      params.model = this.$store.state.model.id
      params.timing = this.timingConstraints
      this.$http.post(`/api/v2/behaviour/update_node_timing/${node}`, params)
        .then(({ data }) => {
          this.isLoading = false
          params.id = node
          this.$emit('updated', params)
        })
    },
    removeTiming(src, tgt) {
      const params = {}
      params.model = this.$store.state.model.id
      params.source = src
      params.target = tgt
      this.$http.post('/api/v2/behaviour/delete_node_timing', params)
        .then(() => {
          this.isLoading = false
          this.$emit('updated')
        })
    },
    saveSegmentCaseAndExecution(item, bn, exec) {
      this.isLoading = true
      const params = {}
      params.model = this.$store.state.model.id
      params.bn = bn.details.id
      params.pre = bn.preconditions
      params.triggers = bn.triggers
      params.post = item.state
      params.qualifiers = item.qualifiers
      params.actual = item.actual
      params.issues = this.execIssues.map(x => (x.value.id))
      params.exec = exec
      this.$http.post('/api/v2/behaviour/generate_tc_from_segment', params)
        .then(({ data }) => {
          this.isLoading = false
          this.execIssues = []
          this.issueScope = ''
          params.id = params.bn
          this.$emit('updated', params)
        })
    },
    // -- Utility -- //
    repeatObject() {
      this.objects.push({
        id: this.nextObjectId += 1,
      })
    },
    removeObject(index) {
      this.objects.splice(index, 1)
    },

    repeatProperty() {
      this.properties.push({
        id: this.nextPropId += 1,
      })
    },
    removeProperty(index) {
      this.properties.splice(index, 1)
    },
    showLink(bn, link) {
      this.chart.addSlink(bn, link.id, '', 'blue').draw(OrgChart.action.update)
      this.$emit('clickNode', link.id)
    },
    toggleMiniMode() {
      this.mini = !this.mini
      localStorage.setItem('behaviourTreeMiniMode', this.mini)
      this.$emit('toggleMiniMode')
      const isKeepData = true
      this.getData(isKeepData)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.dark-layout {
  div ::v-deep {
    .b-overlay {
      .bg-light {
        background-color: $theme-dark-body-bg !important;
      }
    }
  }
}
</style>

<style lang="scss">
#gridState {
  display: grid;
  width: 100%;
  grid-template-columns: max-content 1fr max-content;
  column-gap: 1rem;
}

.mini .b-sidebar {
  border-top-left-radius: 0.428rem;
  width: 100rem;
  height: 55vh;
  bottom: 0;
  top: revert;
}

.mini .btn {
  padding: 0.486rem 1rem;
  border-radius: 0.358rem;
  line-height: 1;
  font-size: 0.9rem;
}

.mini input {
  padding: 0.486rem 1rem;
  border-radius: 0.358rem;
  line-height: 1;
  height: 2rem;
  font-size: 0.9rem;
  display: inline-block;
}

.mini select {
  padding: 0.486rem 1rem;
  border-radius: 0.358rem;
  line-height: 1;
  height: 2rem;
  font-size: 0.9rem;
  display: inline-block;
  width: 80%;
}

.mini label {
  display: inline-block;
  font-size: 0.9rem;
}

.mini #savebn {
  position: absolute;
  top: revert;
  bottom: 0.5rem;
  right: 0.5rem;
}

.mini .nav-item {
  display: none;
}

.mini .form-group {
  margin-bottom: 3px;
}

.mini-mode-btn {
  position: absolute;
  right: 0;
  z-index: 999;
}

.tblHeaderHidden {
  display: none;
}

tr[id^='tblBehaviourNodeRequirements__details_'] > td {
  padding-top: 0;
  padding-right: 0;
}
</style>
